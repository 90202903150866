
function Body({ children }) {
    return <div className="max-w-4xl mx-auto px-10 pb-20 pt-72 print:pt-0">
        {children}
    </div>
}

function Title({ children }) {
    return <h1 className='bg-black text-white uppercase font-bold p-1 px-3 inline-block mt-10 mb-3 print:mt-7'>{children}</h1>
}

function Copy({ children, className }) {
    return <p className={className}>{children}</p>
}

function Item({ title, date, children }) {
    return (<>
        <h2 className='font-bold text-md'>{title}</h2>
        <h3 className='uppercase text-neutral-400 font-bold text-sm'>{date}</h3>
        <Copy className="mb-3">{children}</Copy>
    </>)
}

export { Body, Title, Copy, Item };