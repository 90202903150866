import { Topbar, Footer } from "./helpers/LayoutHelpers";
import WorkedWith from "./helpers/WorkedWith";
import { Body, Title, Copy, Item } from "./helpers/TextHelpers";
import "./App.css";

function NoBreak({ children }) {
  return <div className="break-inside-avoid">{children}</div>;
}

function App() {
  let printFunction = () => { 
    window.print(); 
  }

  return (
    <div className="h-full">
      <Topbar />
      <Body>
        <NoBreak>
          <Title>Profile</Title>
          <Copy>
            Driven and creative Computer Science student with a passion for new
            technology. Currently completing second year of CS BSc while
            contracting for clients in tech fields and volunteering development
            services for local organisations.
            <br />
            <br /> I have experience across the tech sector, from low level
            hardware implementation to beautiful, responsive, performant web
            apps and the databases behind them.
            <br />
            <br />
            I'm interested in delivering solutions in hardware and software that
            meet client needs and integrate smoothly in their environment.
          </Copy>
        </NoBreak>

        <NoBreak>
          <Title>Internships</Title>
          <Item
            title="UPCOMING: Siemens D-Cubed Internship, Cambridge"
            date="July 2024 - October 2024"
          >
            Over summer 2024 I will complete an internship in C++ development at Siemens, within their D-Cubed CAD team.
          </Item>
        </NoBreak>

        <NoBreak>
          <Title>Employment History</Title>
          <Item
            title="Email Campaign Developer, Marcus Sanderson LTD, Corby Glen"
            date="July 2021 - Present"
          >
            Designed and built Email Campaigns for large companies. I've written
            industry-ready code and been responsible for test and release of
            emails that have reached upwards of 100 million people, working with
            the large companies listed below.
          </Item>
          <Item
            title="Systems Design Contracting, Airblast Eurospray LTD, Grantham"
            date="August 2022 - September 2022"
          >
            Built and delivered an IoT hardware solution and fullstack webapp to
            record, store, and serve workshop tasks via an app to be displayed
            on monitors across the organisation. The app is Cloud Hosted to
            avoid downtime and uses IoT management tools from www.balena.io with
            real time messaging from Firebase.
          </Item>
          <Item
            title="Kitchen Porter, Amore Italian, Stamford"
            date="April 2023 - September 2023"
          ></Item>
        </NoBreak>

        <NoBreak>
          <Title>I've written code for:</Title>
          <WorkedWith />
        </NoBreak>

        <NoBreak>
          <Title>Projects</Title>
          <Item
            title="Movein - App to find Housemates, Director at LRWB Ltd"
            date="May 2023 - Present"
          >
            Founded a start-up as part of Durham's Venture Lab designed to help
            students find housemates and houses. We developed an app in Flutter
            using a Firebase backend to allow users on Android and iOS to find
            housemates with similar interests. We also built an instant
            messaging feature and use Azure as our image storage backend.
          </Item>
          <Item
            title="IoT Challenge - Hamster Tracker"
            date="September 2020 - August 2022"
          >
            Developed an IoT Hamster Wheel able to track and record the distance
            run by a hamster before plotting this information on a world map.
            The system allows multiple users to race their pets across the map
            in an effort to make small pets more engaging. Built using an
            ESP8266 and written in C++, React, Python and Node.js.
          </Item>
          <Item title="Inspection Robot" date="September 2020 - August 2022">
            Using industry leading CAD software I designed and built a remote
            inspection robot using 3D printing and Laser Cutting in consultation
            with an industry stakeholder. The system controller design uses an
            Arduino programmed in C++ to communicate with a custom remote
            controller to control four stepper motors and uses radio frequency
            to stream realtime images back to the operator.
          </Item>
        </NoBreak>

        <NoBreak>
          <Title>Work Experience</Title>
          <Item
            title="Perkins Engines Company (Caterpillar)"
            date="Summer 2018"
          >
            Completed a week long work experience program at Perkins including
            placements in various Business units, including their Global Engine
            Development Center. Worked in CAD and presented to multiple
            Engineers on the technicalities of HEUI fuel injectors and gained
            experience of a corporate work environment.
          </Item>
          <Item title="Work Experience at Saint James Place" date="Spring 2020">
            Completed a three day work experience placement at St James Place
            learning about their Investment and Risk Management operations.
          </Item>
        </NoBreak>

        <NoBreak>
          <Title>Education</Title>
          <Item
            title="Computer Science BSc, Durham University, UK"
            date="September 2022 - Present"
          >
            Currently completing my second year, achieved an overall first in
            first year and on track for the same in year two.
          </Item>
          <Item
            title="4 A* grades at A Level, Bourne Grammar School, Lincolnshire"
            date="September 2020 - July 2022"
          >
            Achieved four A-star grades in Computer Science, Mathematics,
            Physics, and Design Engineering.
          </Item>
          <Item
            title="GCSEs, Bourne Grammar School, Lincolnshire"
            date="September 2018 - July 2020"
          >
            Achieved ten grade 9s at GCSE, including Computer Science, Physics,
            Maths, Further Maths, English Lit/Lang, and Design Engineering.
          </Item>
        </NoBreak>

        <NoBreak>
          <Title>Volunteering</Title>
          <Item
            title="Full Stack development and site management, Bookflix"
            date="September 2021 - Present"
          >
            Built a multi-user book review and recommendation site for a school
            book club. Site built using Node.js, React, and a MySQL database.
          </Item>
          <Item
            title="Shopkeeper, Castle Bytham Village Shop"
            date="May 2018 - June 2021"
          >
            Volunteered to run the checkout in a community run village shop.
          </Item>
          <Item
            title="Youth Club Worker, Corby Glen Youth Club"
            date="May 2018 - June 2021"
          >
            Setup and ran a village youth club for young people in my area.
          </Item>
        </NoBreak>

        <NoBreak>
          <Title>Details</Title>
          <Copy>Durham/Peterborough, United Kingdom</Copy>
          <a className="underline" href="mailto:benmacwill@gmail.com">
            <Copy>benmacwill@gmail.com</Copy>
          </a>
          <Copy>07495 421645</Copy>
        </NoBreak>

        <button style={{ backgroundColor: "#FFE04B" }} className="print:hidden bg-neutral-400 text-black font-bold p-1 px-3 mt-10 mb-3 pointer rounded-lg hover:rounded-none" onClick={printFunction}>Download page</button>
      </Body>
      <Footer />
    </div>
  );
}

export default App;
