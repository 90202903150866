import { ReactComponent as ReactLogo } from "../images/react.svg";
import { ReactComponent as TailwindLogo } from "../images/tailwind.svg";
import { ReactComponent as JSLogo } from "../images/js.svg";
import { Copy } from "./TextHelpers";
import { useEffect, useState } from "react";

function Topbar() {
  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    setScrollPos(window.scrollY);
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-full py-10 pl-10 top-0 fixed print:py-5 print:relative ${
        scrollPos ? "shrink" : ""
      }`}
      style={{ backgroundColor: "#FFE04B" }}
    >
      <div>
        <h1 className="text-5xl sm:text-7xl print:!text-6xl font-bold uppercase w-10">
          Ben Williamson
        </h1>
        <h2 className="text-base sm:text-xl print:!text-lg mt-2">
          BSc Computer Science Student / Software Developer
        </h2>
      </div>
    </div>
  );
}

function Footer({ children }) {
  return (
    <div
      className="w-full px-10 py-4 print:fixed print:bottom-0 print:py-2"
      style={{ backgroundColor: "#FFE04B" }}
    >
      <div className="print:hidden">
        <Copy className="text-center font-bold pb-4">Made with</Copy>
        <div className="flex flex-row gap-5 content-center justify-center flex-wrap">
          <ReactLogo className="animate-spin-slow w-10" />

          <TailwindLogo className="w-10 animate-[bounce_1.5s_infinite_100ms]" />

          <JSLogo className="w-10 animate-bounce shadow-lg" />
        </div>
        <Copy className="text-center  font-bold pb-0">By Ben</Copy>
      </div>

      <div className="flex flex-row">
        <a className="underline" href="https://benwill.dev">
          <Copy>benwill.dev</Copy>
        </a>
        <Copy className="ml-auto"><span className="print:hidden">©</span>{new Date().getFullYear()}</Copy>
      </div>
    </div>
  );
}

export { Topbar, Footer };
