import { ReactComponent as ExperianLogo } from "../images/workedWith/Experian.svg"
import { ReactComponent as BrittanyFerries } from "../images/workedWith/Brittany_Ferries.svg"
import { ReactComponent as Klaviyo } from "../images/workedWith/klaviyo.svg"
import { ReactComponent as MadameTussauds } from "../images/workedWith/Madame_Tussauds.svg"
import { ReactComponent as MetLife } from "../images/workedWith/metLife.svg"
import Adelphi from "../images/workedWith/adelphi.png"
import lv from "../images/workedWith/lv.png"

function Logo({ Source, Key }) {
    const imageContainerClasses = "self-center";

    return <div key={Key} className="w-32 sm:w-40 print:!w-[4rem] flex justify-center align-center flex-wrap">
        {typeof (Source) === "object" ? <Source className={imageContainerClasses} /> : <img alt="" className={imageContainerClasses} src={Source} />}
    </div>
}

export default function WorkedWith() {
    const logos = [ExperianLogo, BrittanyFerries, MetLife, MadameTussauds, lv, Adelphi, Klaviyo]

    return <div className="flex flex-wrap justify-center gap-5 sm:gap-10 my-10 print:my-5">
        {logos.map((logo, key) => {
            return <Logo Source={logo} Key={key} />
        })}
    </div>
}